/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Button, formatCurrency } from '@spa-cars/ui';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { validateString } from 'avilatek-utils';
import {
  Appointment,
  Client,
  Currency,
  Order,
  paymentProcessorsEnum,
  Shopcart,
} from '@spa-cars/models';
import { ACTIONS, ACTIONS_TYPE, PaymentState, TTotal } from '../reducer';
import { useNotify, useShopCart, useUser } from '../../../hooks';
import {
  ADD_TO_SHOPCART,
  CREATE_ORDER,
  CREATE_PAYMENT_INTENT_LUKA,
  VERIFY_VEHICLE_OWNER,
} from '../../../graphql/mutations';
import TermsAndConditions from './TermsAndConditions';
import ConfirmButton from './ConfirmButton';
import StripeButton from './StripeButton';

interface PaymentBtnProps {
  state: Partial<PaymentState>;
  dispatch: React.Dispatch<ACTIONS_TYPE>;
  appointment: Appointment;
  localCurrency: Currency;
}

export default function PaymentBtn({
  state,
  dispatch,
  appointment,
  localCurrency,
}: PaymentBtnProps) {
  const notify = useNotify();
  const router = useRouter();
  const [user] = useUser();
  const [shopCart] = useShopCart();

  const [processorSelected, setProcessorSelected] =
    React.useState<paymentProcessorsEnum>(null);

  const [createOrder] = useMutation<{
    createOrder: { data: Order };
  }>(CREATE_ORDER);

  const [addToShopcart] = useMutation<{
    addToShopcart: { shopcart: Shopcart; tally: TTotal };
  }>(ADD_TO_SHOPCART);

  const [verifyVehicleOwner] = useMutation<{
    verifyVehicleOwner: { verified: boolean };
  }>(VERIFY_VEHICLE_OWNER);

  const [createPaymentIntentLuka] = useMutation<{
    createPaymentIntentLuka: {
      url: string;
    };
  }>(CREATE_PAYMENT_INTENT_LUKA);

  const payWithGiftCard = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      if (state.confirmedForm) {
        const promoCodes = state.appliedDiscounts.map((item) => item.code);
        await createOrder({
          variables: {
            data: {
              shopcart: shopCart._id,
              appointment: appointment._id,
              paymentStatus: 'approved',
              promoCodes,
              paymentInfo: {
                status: 'approved',
                gateway: 'otros',
                amount: Math.max(state.total?.total ?? 0, 0),
                currency: 'ds',
              },
            },
          },
        });
        router.push('/thanks');
      } else {
        return notify('Error', 'error');
      }
    } catch (error) {
      return notify(`${error}`, 'error');
    }
  };

  /**
   * @description: validate selects
   * @returns toast notify
   */
  const confirmForm = async () => {
    dispatch({ type: ACTIONS.LOADING_SHOPCART, payload: true });
    try {
      if (
        !validateString(state.oilFilter) ||
        String(state.oilFilter).length > 127
      ) {
        return notify('Selección de filtro aceite inválida', 'error');
      }
      if (
        !validateString(state.engineOil) ||
        String(state.engineOil).length > 127
      ) {
        return notify('Selección de aceite de motor inválida', 'error');
      }
      if (!validateString(state.flush) && state.withFlush) {
        return notify('Selección flush inválido', 'error');
      }

      // if (
      //   !validateString(state.wiperWasher) ||
      //   String(state.wiperWasher).length > 127
      // ) {
      //   return notify('Selección de limpia parabrisas inválida', 'error');
      // }

      const { data } = await verifyVehicleOwner({
        variables: {
          data: {
            vehicleId: appointment?.vehicle?._id,
            clientId: (user?.client as Client)?._id,
          },
        },
      });

      if (!data.verifyVehicleOwner?.verified) {
        return notify(
          'El vehículo no pertenece al usuario que está realizando la cita',
          'error'
        );
      }
      await AddItemsToShopcart();

      dispatch({ type: ACTIONS.CONFIRMED_FORM, payload: true });
    } catch (error) {
      notify('Error al confirmar formulario', 'error');
    } finally {
      dispatch({ type: ACTIONS.LOADING_SHOPCART, payload: false });
    }
  };

  const AddItemsToShopcart = async () => {
    const items = state.shopcartProducts.map((item) => ({
      productId: item.product._id,
      variantId: item.variant._id,
      quantity: item.quantity,
    }));
    try {
      await addToShopcart({
        variables: {
          data: {
            items,
            shopcartId: shopCart._id,
          },
        },
      });
    } catch (error) {
      return notify(error, 'error');
    }
  };

  return (
    <>
      {!state.confirmedForm ? (
        <ConfirmButton confirmForm={confirmForm} state={state} />
      ) : null}
      <div className="flex w-full items-center gap-2 flex-col mt-3">
        {state.confirmedForm && state.total.total > 0 ? (
          <>
            {/* ubii */}
            {processorSelected !== 'stripe' ? (
              <>
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    dispatch({
                      type: ACTIONS.IN_PAYMENT_PROCESS,
                      payload: true,
                    });
                    const { data } = await createPaymentIntentLuka({
                      variables: {
                        data: {
                          currency: 'VES',
                          email: appointment.user.email,
                          reservationNumber: `${appointment._id}-${shopCart._id}`,
                          total: state.total.total * localCurrency.rate,
                          from: 'client',
                        },
                      },
                    });
                    window.location.replace(data.createPaymentIntentLuka.url);
                  }}
                >
                  Pagar con VES
                </Button>
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    dispatch({
                      type: ACTIONS.IN_PAYMENT_PROCESS,
                      payload: true,
                    });
                    const { data } = await createPaymentIntentLuka({
                      variables: {
                        data: {
                          currency: 'USD',
                          email: appointment.user.email,
                          reservationNumber: `${appointment._id}-${shopCart._id}`,
                          total: state.total.total,
                          from: 'client',
                        },
                      },
                    });
                    window.location.replace(data.createPaymentIntentLuka.url);
                  }}
                >
                  Pagar con USD
                </Button>
              </>
            ) : null}
            {/* Stripe */}
            {processorSelected !== 'ubii' ? (
              <StripeButton
                state={state}
                processorSelected={processorSelected}
                setProcessorSelected={setProcessorSelected}
              />
            ) : null}
          </>
        ) : null}
        {/* si el total es 0 no se usa procesador de pago */}
        {state.confirmedForm && state.total.total <= 0 ? (
          <Button onClick={payWithGiftCard} className="my-5">
            Pagar
          </Button>
        ) : null}
      </div>
      <TermsAndConditions />
    </>
  );
}
