import React from 'react';

function TermsAndConditions() {
  return (
    <div className="mt-2  text-center text-sm justify-center items-center">
      Al continuar, estás de acuerdo con nuestros{' '}
      <div className="flex  justify-center">
        <a
          target="_blank"
          href="https://dev.spacars.app/terms-and-conditions"
          rel="noopener noreferrer"
          className=" text-center text-sm flex justify-center"
        >
          <span className="underline text-primary-400 font-semibold cursor-pointer">
            Términos y Condiciones
          </span>
        </a>
        <span className="mx-1"> y </span>
        <a
          target="_blank"
          href="https://dev.spacars.app/privacy"
          rel="noopener noreferrer"
          className=" text-center text-sm flex justify-center"
        >
          <span className="underline text-primary-400 font-semibold cursor-pointer">
            Políticas de Privacidad
          </span>
        </a>
      </div>
    </div>
  );
}

export default TermsAndConditions;
